import React, { useState, useRef, useEffect } from 'react'
import styles from '../styles/About.module.css'
import Image from 'next/image'
import HomeImage from '../public/img/Home.webp'
import data from '@/public/data/AllData.json'
import { useTranslation } from 'next-i18next'
const SearchBarFilter = () => {
  const grayscaleStyle = {
    filter: 'grayscale(100%)', // Apply grayscale filter
  }
  const { t, i18n } = useTranslation()

  const placeholder = 'Search...'
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const handleInputChange = (event) => {
    const { value } = event.target
    setSearchTerm(value)
    if (value === '') {
      setFilteredData([])
      setSubmitted(false) // Reset submitted when input is empty
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmitted(true)
    if (searchTerm.trim() !== '') {
      filterData(searchTerm)
    } else {
      setFilteredData([])
    }
  }

  const filterData = (searchTerm) => {
    if (!data) return
    const filteredData = data.filter(
      (item) =>
        (item.Name &&
          item.Name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.Ingredients &&
          item.Ingredients.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.Title &&
          item.Title.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    setFilteredData(filteredData)
  }

  return (
    <search className={styles.box}>
      <form name='search' onSubmit={handleSubmit}>
        <input
          type='text'
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleInputChange}
          className={styles.input}
          name='txt'
          autoComplete='on'
          pattern='^[a-zA-Z]*$'
          ref={inputRef}
        />
      </form>
      {submitted && filteredData.length === 0 && searchTerm.trim() !== '' && (
        <p className={styles.noResults}>
          {
            // English text
            t('No results found')
          }
        </p>
      )}
      {filteredData.length > 0 && (
        <section className={styles.cardContainer}>
          <div className={styles.card}>
            <div className={styles.cardsContainer}>
              {filteredData.map((data, id) => (
                <ol role='list' className={`${styles.cardItem}`} key={id}>
                  <li className={styles.imageContainer}>
                    <div className={styles.imageWrapper}>
                      <Image
                        src={data.imageUrl || HomeImage}
                        style={data.imageUrl ? { HomeImage } : grayscaleStyle}
                        className={styles.imageUrl}
                        width={200}
                        height={100}
                        alt={data.alt}
                        aria-label={data.alt || 'Image Not Available'}
                        loading='lazy'
                      />
                      {!data.imageUrl && (
                        <span
                          className={styles.comingSoon}
                          aria-label='Image Not Available'
                        >
                          {t('Image Not Available')}
                        </span>
                      )}
                    </div>
                  </li>
                  <figcaption className={styles.figCaption}>
                    {data.Name}
                  </figcaption>
                  {data.Ingredients && (
                    <span className={styles.cardSpan}>{data.Ingredients}</span>
                  )}
                  <div className={styles.cardItemWrapper}>
                    {data.Barn && (
                      <span className={styles.cardSpan}>
                        Barn: {data.Barn}kr
                      </span>
                    )}
                    {data.Regular && (
                      <span className={styles.cardSpan}>
                        Regular: {data.Regular}kr
                      </span>
                    )}
                    {data.Familj && (
                      <span className={styles.cardSpan}>
                        {t('Family')}: {data.Familj}kr
                      </span>
                    )}
                    {data.Tallrik ? (
                      <span className={styles.cardSpan}>
                        {t('Plate')}: {data.Tallrik}kr
                      </span>
                    ) : null}
                    {data.Rulle ? (
                      <span className={styles.cardSpan}>
                        {t('Roll')}: {data.Rulle}kr
                      </span>
                    ) : null}
                    {data.Bröd ? (
                      <span className={styles.cardSpan}>
                        {t('Bread')}: {data.Bröd}kr
                      </span>
                    ) : null}
                  </div>
                </ol>
              ))}
            </div>
          </div>
        </section>
      )}
    </search>
  )
}

export default SearchBarFilter
